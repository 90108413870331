window.$ = window.jQuery = require('jquery');

import LazyLoad from "vanilla-lazyload";

require('slick-carousel');
require('jquery-viewport-checker');
require('bootstrap');
// require('leaflet');

$(function () {

    $( document ).ready(function() {
        
        const lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
            // aby użyć do elementów dodajemy klasę .lazy
            // zamiast src w img podajemy data-src
            // zamiast background-image podajemy data-bg
        });

        $('.top-slider').slick({
          dots: false,
          infinite: true,
          speed: 2000,
          fade: true,
          cssEase: 'linear',
          arrows: false,
          autoplay: true,
          pauseOnHover: false
        });
      });
});